<template>
  <el-main>
    <Drawer
      :visibility="isVisible"
      drawerName="Comentar item"
      @close="handleClose"
      @save="handleSaveComment"
    >
      <div>
        <el-row v-if="info.month" class="comment-date">
          <span>
            {{ `Mês: ${handleFormatDate(info.month)}` }}
          </span>
        </el-row>
        <el-row
          v-for="(field, index) in commentsFields"
          :key="index"
          class="input-container"
        >
          <span class="input-container__title">
            {{ handleFormatLabel(field) }}
          </span>
          <span> {{ `Valor: ${handleFormatValue(info[field])}` }} </span>
          <span class="input-container__sub-title">Comentário:</span>
          <el-input
            v-model="comments[field]"
            type="textarea"
            :rows="5"
            placeholder="Adicionar comentário..."
          />
        </el-row>
      </div>
    </Drawer>
  </el-main>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'
import { inputLabels } from '@/utils/costTracking'

export default {
  name: 'DrawerAdjustment',
  components: {
    Drawer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      commentsFields: [],
      comments: {},
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleSaveComment() {
      let sendData = { ...this.info }
      sendData['comment'] = this.comments
      this.$emit('save', sendData)
    },
    handleFormatLabel(label) {
      return inputLabels[label]
    },
  },
  computed: {},
  watch: {
    info() {
      this.comments = { ...this.info.comment }
      this.commentsFields = Object.keys(this.info.comment)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
