import mixins from '@/mixins'

export const rubricTableType = {
  equip: 'generic',
  civil: 'generic',
  hr: 'generic',
  books: 'generic',
  consumables: 'generic',
  trips: 'generic',
  training: 'generic',
  external: 'generic',
  correlates: 'correlates',
  expenses: 'generic',
  costs: 'generic',
  total: 'generic',
  contribution: 'generic',
  finalBalance: 'finalBalance',
  openingBalance: 'initialBalance',
}

export const inputLabels = {
  baseline: 'Baseline',
  totalPredicted: 'Total previsto',
  totalRealized: 'Total realizado',
  totalsSum: 'Totais somados',
  difference: 'Diferença',
  realized: 'Real',
  replan: 'Replanejado',
  totalRealizedOC1: 'Total realizado correlato 1',
  totalRealizedOC2: 'Total realizado correlato 2',
  realizedCorrel1: 'Real correlato 1',
  realizedCorrel2: 'Real correlato 2',
  value: 'Saldo inicial',
}

export const tableinitialBalanceConfig = {
  actions: [{ value: 'comment', label: 'Comentar' }],
  tableColumnsProps: [
    {
      label: 'Valor',
      prop: 'value',
      width: 180,
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
  ],
  tableIconColumnsProps: [],
}

export const tableGeneralConfig = {
  generic: {
    actions: [{ value: 'comment', label: 'Comentar' }],
    tableColumnsProps: [
      {
        label: 'Baseline (SOW)',
        prop: 'baseline',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Total previsto',
        prop: 'totalPredicted',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Total realizado',
        prop: 'totalRealized',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Total realizado + previsto',
        prop: 'totalsSum',
        width: 200,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Diferença',
        prop: 'difference',
        width: 150,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
    ],
    tableIconColumnsProps: [],
  },
  correlates: {
    actions: [{ value: 'comment', label: 'Comentar' }],
    tableColumnsProps: [
      {
        label: 'Baseline (SOW)',
        prop: 'baseline',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Total previsto',
        prop: 'totalPredicted',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Total realizado',
        prop: 'totalRealized',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Total realizado OC 1',
        prop: 'totalRealizedOC1',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Total realizado OC 2',
        prop: 'totalRealizedOC2',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Total realizado + previsto',
        prop: 'totalsSum',
        width: 200,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Diferença',
        prop: 'difference',
        width: 150,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
    ],
    tableIconColumnsProps: [],
  },
  initialBalance: {
    actions: [],
    tableColumnsProps: [],
    tableIconColumnsProps: [],
  },
  finalBalance: {
    actions: [],
    tableColumnsProps: [],
    tableIconColumnsProps: [],
  },
}

export const tableMonthlyConfig = {
  generic: {
    actions: [{ value: 'comment', label: 'Comentar' }],
    tableColumnsProps: [
      {
        label: 'Mês',
        prop: 'month',
        width: 180,
        minWidth: 150,
        formatter: formatTableDate,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Baseline',
        prop: 'baseline',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Replanejado',
        prop: 'replan',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Real',
        prop: 'realized',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [],
  },
  correlates: {
    actions: [{ value: 'comment', label: 'Comentar' }],
    tableColumnsProps: [
      {
        label: 'Mês',
        prop: 'month',
        width: 180,
        minWidth: 150,
        formatter: formatTableDate,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Baseline',
        prop: 'baseline',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Replanejado',
        prop: 'replan',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Real OC 1',
        prop: 'realizedCorrel1',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Real OC 2',
        prop: 'realizedCorrel2',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Real total',
        prop: 'realized',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [],
  },
  initialBalance: {
    actions: [],
    tableColumnsProps: [],
    tableIconColumnsProps: [],
  },
  finalBalance: {
    actions: [{ value: 'comment', label: 'Comentar' }],
    tableColumnsProps: [
      {
        label: 'Mês',
        prop: 'month',
        width: 180,
        minWidth: 150,
        formatter: formatTableDate,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
      },
      {
        label: 'Real',
        prop: 'realized',
        width: 180,
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [],
  },
}

function formatTableValue(row, column, cellValue, index) {
  return mixins.methods.handleFormatValue(cellValue)
}

function formatTableDate(row, column, cellValue, index) {
  return mixins.methods.handleMonthAndYearFormatter(cellValue)
}

function formatTableData(row, column, cellValue, index) {
  return cellValue !== undefined && cellValue.length > 0 ? cellValue : '-'
}

function formatTableCPF(row, column, cellValue, index) {
  return mixins.methods.handleFormatCPF(cellValue)
}
