<template>
  <div class="tab-container">
    <div class="tab-header">
      <h2 class="tab-title">{{ title }}</h2>
      <el-button
        class="primary-button"
        @click="drawerTransferVisibled = true"
        :loading="costCenterCode ? false : true"
        >Transferir valor</el-button
      >
    </div>
    <div class="container">
      <h3 class="tab-sub-title">Informações mensais</h3>
      <DisplayFinalBalanceCostTrackingTable
        :generalInfoId="generalInfoId"
        :tableData="info.finalBalance"
        @comment="handleComment"
      />
    </div>
    <DisplayDrawerTransfer
      :visibility="drawerTransferVisibled"
      :projects="projects"
      :availableValue="getAvailableValue"
      :costCenterName="costCenterName"
      @onClose="handleCloseDrawer"
      @onSave="handleSaveTransferData"
    />
  </div>
</template>

<script>
import DisplayFinalBalanceCostTrackingTable from './Partials/DisplayFinalBalanceCostTrackingTable/DisplayFinalBalanceCostTrackingTable.vue'
import DisplayDrawerTransfer from './Partials/DisplayDrawerTransfer/DisplayDrawerTransfer.vue'
import {
  findCostCentersByClient,
  resourceTransfer,
} from '@/service/costCenters'
import { startOfMonth } from 'date-fns'

import { mapGetters } from 'vuex'

export default {
  name: 'FinalBalanceCostTrakingPane',
  props: {
    generalInfoId: String,
    costCenterName: String,
    costCenterCode: {
      type: String,
      default: () => null,
    },
    title: {
      type: String,
      default: () => '',
    },
    info: {
      type: Object,
      default: () => {
        return {
          finalBalance: [],
        }
      },
    },
  },
  data() {
    return {
      drawerTransferVisibled: false,
      projects: [],
    }
  },
  components: {
    DisplayFinalBalanceCostTrackingTable,
    DisplayDrawerTransfer,
  },
  computed: {
    getAvailableValue() {
      return (
        this.info?.finalBalance?.find(
          (elem) =>
            startOfMonth(new Date(elem.month)).toISOString() ===
            startOfMonth(this.getSelectedMonth()).toISOString()
        )?.realized || 0
      )
    },
  },
  methods: {
    ...mapGetters(['getSelectedMonth']),
    handleComment(rowInfo) {
      this.$emit('comment', rowInfo)
    },
    handleCloseDrawer() {
      this.drawerTransferVisibled = false
    },
    async handleSaveTransferData(obj) {
      let loading = this.$loading({
        lock: true,
        text: 'Transferindo valor...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const dataObj = { ...obj, originCcGenInfoId: this.generalInfoId }
        await resourceTransfer(dataObj)
        this.$message({
          showClose: true,
          type: 'success',
          message: 'Transferência realizada com sucesso!',
          duration: 5000,
        })
        this.handleCloseDrawer()
        this.$emit('onRefresh')
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    async fetchCostCenters() {
      try {
        const { data } = await findCostCentersByClient(
          this.getSelectedMonth(),
          this.costCenterCode
        )
        this.projects = data || []
      } catch (error) {}
    },
  },
  created() {
    this.fetchCostCenters()
  },
  watch: {
    costCenterCode() {
      this.fetchCostCenters()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
