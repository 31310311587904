<template>
  <el-table
    :data="tableData"
    height="100%"
    size="small"
    max-height="100%"
    class="rubric-container__table-inner"
  >
    <el-table-column type="expand">
      <template slot-scope="props">
        <div v-if="props.row?.extract.length > 0">
          <div
            class="table-expand-item"
            v-for="(item, index) in props.row?.extract"
            :key="'item-description-' + index"
          >
            <p>
              {{ getItemTextFormatter(item) }}
            </p>
          </div>
        </div>
        <div v-else class="table-expand-item"><p>Sem informações.</p></div>
      </template>
    </el-table-column>
    <el-table-column label="Mês" prop="month" :formatter="dateFormatter">
    </el-table-column>
    <el-table-column label="Real" prop="realized" :formatter="moneyFormatter">
    </el-table-column>
    <el-table-column label="Transferiu" prop="isTransfer" align="center">
      <template slot-scope="props">
        <el-tooltip
          v-if="props.row.madeTransfer"
          placement="top"
          content="Transferiu valor"
          :open-delay="500"
        >
          <i class="el-icon-success icon-success" />
        </el-tooltip>
        <el-tooltip
          v-else
          placement="top"
          content="Não transferiu valor"
          :open-delay="500"
        >
          <i class="el-icon-warning icon-warning" />
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column label="Recebeu" prop="isReceived" align="center">
      <template slot-scope="props">
        <el-tooltip
          v-if="props.row.receivedTransfer"
          placement="top"
          content="Recebeu valor"
          :open-delay="500"
        >
          <i class="el-icon-success icon-success" />
        </el-tooltip>
        <el-tooltip
          v-else
          placement="top"
          content="Não recebeu valor"
          :open-delay="500"
        >
          <i class="el-icon-warning icon-warning" />
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column width="100" label="Ações" align="center">
      <template slot-scope="scope">
        <div>
          <el-button
            class="btn-action"
            @click="handleAction('comment', scope.row)"
          >
            Comentar
          </el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'GenericTable',
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    generalInfoId: String,
  },
  data() {
    return {
      description: [
        'O projeto Y recebeu uma transferência no valor de 10.00,00 do projeto X na data 04/22.',
        'O projeto Y recebeu uma transferência no valor de 10.00,00 do projeto X na data 04/22.',
        'O projeto Y recebeu uma transferência no valor de 10.00,00 do projeto X na data 04/22.',
        'O projeto Y recebeu uma transferência no valor de 10.00,00 do projeto X na data 04/22.',
      ],
    }
  },
  methods: {
    handleAction(action, rowInfo) {
      this.$emit(action, { rowInfo })
    },
    dateFormatter(row, column, cellValue, index) {
      return this.handleMonthAndYearFormatter(cellValue)
    },
    moneyFormatter(row, column, cellValue, index) {
      return this.handleFormatValue(cellValue)
    },
    getItemTextFormatter(item) {
      return `O projeto ${item.ccGenInfoOrigin.cost_center.name} ${
        this.generalInfoId === item.ccGenInfoOriginId ? 'transferiu' : 'recebeu'
      }  um valor de ${this.handleFormatValue(item.value)} ${
        this.generalInfoId !== item.ccGenInfoOriginId ? 'do' : 'para o'
      } projeto  ${
        item.ccGenInfoDestiny.cost_center.name
      } na data ${this.handleFormatDate(item.created_at)}.`
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
