import api from '../instance'

export function fetchGetCommentsCostTracking(rubricList, generalInfoId) {
  return api.get(
    `/commentCostTracking?rubric=${rubricList}&generalInfoId=${generalInfoId}`
  )
}

export function fetchUpsertCommentsCostTracking(data) {
  return api.put('/commentCostTracking', data)
}
