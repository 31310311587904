<template>
  <div class="header-info-container">
    <el-button
      icon="el-icon-arrow-left"
      id="return-button"
      @click="handleReturn"
    ></el-button>
    <div class="info-container">
      <span>
        {{ contextText }} centro de custo {{ info.centerCostCode }} -
        {{ info.projectName }}
      </span>
      <span v-if="hasReferenceMonth" class="info-container-date">
        Mês de referência: {{ handleMonthAndYearFormatter(info.startDay) }} a
        {{ handleMonthAndYearFormatter(info.endDay) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnHeader',
  data() {
    return {}
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          centerCostCode: '',
          projectName: '',
          startDay: new Date(),
          endDay: new Date(),
        }
      },
    },
    hasReferenceMonth: {
      type: Boolean,
      default: () => false,
    },
    contextText: {
      type: String,
      default: () => 'Informações',
    },
  },
  methods: {
    handleReturn() {
      this.$router.push({ name: 'Export' })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
