<template>
  <el-table
    height="100%"
    size="small"
    max-height="100%"
    class="rubric-container__table-inner"
    :data="tableData"
  >
    <el-table-column
      v-for="(element, index) in tableProps.tableColumnsProps"
      :key="`generic-table-column-${element.label}-${index}`"
      :prop="element.prop"
      :label="element.label"
      :min-width="element.minWidth"
      :width="element.width"
      :formatter="element.formatter"
      show-overflow-tooltip
      :align="element.align"
      :header-align="element.headerAlign"
      :sortable="element.sortable"
    />
    <el-table-column
      v-for="(column, index) in tableProps.tableIconColumnsProps"
      :key="`generic-table-icon-column-${column.label}-${index}`"
      :prop="column.prop"
      :label="column.label"
      :min-width="column.minWidth"
      :width="column.width"
      :align="column.align"
      :header-align="column.headerAlign"
      show-overflow-tooltip
      :sortable="column.sortable"
    >
      <template slot-scope="scope">
        <el-tooltip
          v-if="handleIconControl(scope)"
          placement="top"
          :content="handleIconText(scope)"
          :open-delay="500"
        >
          <i class="el-icon-success icon-success" />
        </el-tooltip>
        <el-tooltip
          v-else
          placement="top"
          :content="handleIconText(scope)"
          :open-delay="500"
        >
          <i class="el-icon-warning icon-warning" />
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column width="100" label="Ações" align="center">
      <template slot-scope="scope">
        <div v-if="tableProps.actions.length > 1">
          <el-popover placement="left" trigger="hover" :open-delay="300">
            <el-row class="actions-popover">
              <el-button
                v-for="(element, index) in tableProps.actions"
                :key="`generic-pop-over-button-${index}`"
                class="btn-action"
                @click="handleAction(element.value, scope.row)"
              >
                {{ element.label }}
              </el-button>
            </el-row>
            <el-button slot="reference" icon="el-icon-more" class="btn-icon" />
          </el-popover>
        </div>
        <div v-else>
          <el-button
            class="btn-action"
            @click="handleAction(tableProps.actions[0].value, scope.row)"
          >
            {{ tableProps.actions[0].label }}
          </el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'GenericTable',
  props: {
    tableProps: {
      type: Object,
      default: () => {
        return {
          tableColumnsProps: [],
          tableIconColumnsProps: [],
          actions: [],
        }
      },
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleAction(action, rowInfo) {
      this.$emit(action, { rowInfo })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
