<template>
  <div class="tab-container">
    <h2 class="tab-title">{{ title }}</h2>
    <el-row>
      <GenericTable
        :tableProps="initialBalanceTableProps"
        :tableData="info.openingBalance"
        @comment="handleComment"
      />
    </el-row>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable/GenericTable.vue'
import { tableinitialBalanceConfig } from '@/utils/costTracking'

export default {
  name: 'InitialBalanceCostTrakingPane',
  props: {
    title: {
      type: String,
      default: () => '',
    },
    info: {
      type: Object,
      default: () => {
        return {
          openingBalance: [],
        }
      },
    },
  },
  data() {
    return {
      initialBalanceTableProps: {},
    }
  },
  components: {
    GenericTable,
  },
  methods: {
    handleTablesConfig() {
      this.initialBalanceTableProps = tableinitialBalanceConfig
    },
    handleComment(rowInfo) {
      this.$emit('comment', rowInfo)
    },
  },
  created() {
    this.handleTablesConfig()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
