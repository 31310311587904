<template>
  <div class="tab-container">
    <h2 class="tab-title">{{ title }}</h2>
    <el-row>
      <h3 class="tab-sub-title">Informações gerais</h3>
      <GenericTable
        :tableProps="generalTableProps"
        :tableData="info.generalCorrelate"
        @comment="handleComment"
      />
    </el-row>
    <el-row>
      <h3 class="tab-sub-title">Informações mensais</h3>
      <GenericTable
        :tableProps="monthlyTableProps"
        :tableData="info.monthlyCorrelates"
        @comment="handleComment"
      />
    </el-row>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable/GenericTable.vue'
import {
  rubricTableType,
  tableGeneralConfig,
  tableMonthlyConfig,
  tableinitialBalanceConfig,
} from '@/utils/costTracking'

export default {
  name: 'CorrelateCostTrakingPane',
  props: {
    title: {
      type: String,
      default: () => '',
    },
    rubricType: {
      type: String,
      default: () => '',
    },
    info: {
      type: Object,
      default: () => {
        return {
          generalCorrelate: [],
          monthlyCorrelates: [],
        }
      },
    },
  },
  data() {
    return {
      initialBalance: [],
      generalTableProps: {},
      monthlyTableProps: {},
    }
  },
  components: {
    GenericTable,
  },
  methods: {
    handleTablesConfig() {
      this.generalTableProps =
        tableGeneralConfig[rubricTableType[this.rubricType]]
      this.monthlyTableProps =
        tableMonthlyConfig[rubricTableType[this.rubricType]]
      this.initialBalanceTableProps = tableinitialBalanceConfig
    },
    handleComment(rowInfo) {
      this.$emit('comment', rowInfo)
    },
  },
  computed: {},
  created() {
    this.handleTablesConfig()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
