<template>
  <el-drawer
    :title="drawerTitle"
    :visible.sync="visibility"
    :size="570"
    direction="rtl"
    :before-close="onClose"
  >
    <div class="drawer-content">
      <label>Transferência de valor do projeto {{ costCenterName }}</label>
      <label
        >Mês:
        <b>{{ handleMonthAndYearFormatter(getSelectedMonth()) }}</b></label
      >
      <el-row class="input-container">
        <span>Transferir para o projeto:</span>
        <el-select
          v-model="selectedProject"
          class="my-margin"
          clearable
          filterable
        >
          <el-option
            v-for="(element, index) in projects"
            :key="'projects' + index"
            :label="element?.projectName"
            :value="element?.generalInfoId"
            :disabled="!element?.generalInfoId ? true : false"
          >
            <el-tooltip
              v-if="!element?.generalInfoId"
              placement="top"
              content="Não existem dados de costtracking"
              :open-delay="500"
            >
              <span>{{ element?.projectName }}</span>
            </el-tooltip>
            <span v-else>{{ element?.projectName }}</span>
          </el-option>
        </el-select>
      </el-row>
      <el-row class="input-container">
        <span
          >Valor disponível para transferência:
          <b>{{ handleFormatValue(availableValue) }}</b></span
        >
        <Money
          class="el-input my-margin el-input__inner"
          v-bind="money"
          v-model="transferValue"
        ></Money>
      </el-row>
      <div class="footer-menu-item">
        <div>
          <el-button class="secondary-button" @click="onClose"
            >Cancelar</el-button
          >
          <el-button
            class="custom-button"
            @click="onSave"
            :disabled="!selectedProject"
            >Transferir</el-button
          >
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { Money } from 'v-money'

export default {
  name: 'DisplayDrawerTransfer',
  data() {
    return {
      transferValue: 0,
      selectedProject: null,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false,
      },
    }
  },
  components: {
    Money,
  },
  props: {
    visibility: Boolean,
    costCenterName: String,
    projects: {
      type: Array,
      default: () => [],
    },
    availableValue: Number,
  },
  methods: {
    ...mapGetters(['getSelectedMonth']),
    onClose() {
      this.transferValue = 0
      this.selectedProject = null
      this.$emit('onClose')
    },
    onSave() {
      this.$confirm(
        `Deseja transferir o valor de ${this.handleFormatValue(
          this.transferValue
        )} do projeto ${this.costCenterName} para o projeto ${
          this.projects.find((e) => e?.generalInfoId === this.selectedProject)
            ?.projectName
        }?`,
        'Confirmação de transferência',
        {
          confirmButtonText: 'Transferir',
          cancelButtonText: 'Cancelar',
          confirmButtonClass: 'primary-button',
          cancelButtonClass: 'secondary-button',
          type: '',
        }
      )
        .then(() => {
          this.$emit('onSave', {
            destinyCcGenInfoId: this.selectedProject,
            month: new Date(this.getSelectedMonth()).toISOString(),
            value: this.transferValue,
          })
        })
        .catch(() => {})
    },
    handleSelectItem(projectName) {
      this.selectedProjectName = projectName
    },
  },
  computed: {
    drawerTitle() {
      return `Transferir valor`
    },
  },
  watch: {
    visibility() {
      if (this.visibility === false) {
        this.transferValue = 0
        this.selectedProject = null
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
