<template>
  <el-main>
    <ReturnHeader
      :hasReferenceMonth="true"
      contextText="Cost tracking do"
      :info="headerInfo"
    />
    <div class="content-container">
      <el-tabs
        tab-position="left"
        :value="getActiveTab"
        @tab-click="handleTabClick"
      >
        <el-tab-pane label="Equipamentos" name="equip">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Equipamentos"
            rubricType="equip"
            v-if="activeTab === 'equip'"
            :info="commentsData.equip"
          />
        </el-tab-pane>
        <el-tab-pane label="Obras Civis" name="civil">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Obras Civis"
            rubricType="civil"
            v-if="activeTab === 'civil'"
            :info="commentsData.civil"
          />
        </el-tab-pane>
        <el-tab-pane label="RH" name="hr">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="RH"
            rubricType="hr"
            v-if="activeTab === 'hr'"
            :info="commentsData.hr"
          />
        </el-tab-pane>
        <el-tab-pane label="Livros" name="books">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Livros"
            rubricType="books"
            v-if="activeTab === 'books'"
            :info="commentsData.books"
          />
        </el-tab-pane>
        <el-tab-pane label="M. de consumo" name="consumables">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Materiais de consumo"
            rubricType="consumables"
            v-if="activeTab === 'consumables'"
            :info="commentsData.consumables"
          />
        </el-tab-pane>
        <el-tab-pane label="Viagens" name="trips">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Viagens"
            rubricType="trips"
            v-if="activeTab === 'trips'"
            :info="commentsData.trips"
          />
        </el-tab-pane>
        <el-tab-pane label="Treinamentos" name="training">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Trainamentos"
            rubricType="training"
            v-if="activeTab === 'training'"
            :info="commentsData.training"
          />
        </el-tab-pane>
        <el-tab-pane label="Serviços de terc." name="external">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Serviços de terceiros"
            rubricType="external"
            v-if="activeTab === 'external'"
            :info="commentsData.external"
          />
        </el-tab-pane>
        <el-tab-pane label="Correlatos" name="correlates">
          <CorrelateCostTrakingPane
            @comment="handleComment"
            title="Correlatos"
            rubricType="correlates"
            v-if="activeTab === 'correlates'"
            :info="commentsData.correlates"
          />
        </el-tab-pane>
        <el-tab-pane label="Total de despesas" name="expenses">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Total de despesas"
            rubricType="expenses"
            v-if="activeTab === 'expenses'"
            :info="commentsData.expenses"
          />
        </el-tab-pane>
        <el-tab-pane label="Custos incorridos" name="costs">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Custos incorridos"
            rubricType="costs"
            v-if="activeTab === 'costs'"
            :info="commentsData.costs"
          />
        </el-tab-pane>
        <el-tab-pane label="Total" name="total">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Total"
            rubricType="total"
            v-if="activeTab === 'total'"
            :info="commentsData.total"
          />
        </el-tab-pane>
        <el-tab-pane label="Aportes" name="contribution">
          <GenericCostTrakingPane
            @comment="handleComment"
            title="Aportes"
            rubricType="contribution"
            v-if="activeTab === 'contribution'"
            :info="commentsData.contribution"
          />
        </el-tab-pane>
        <el-tab-pane label="Saldo final" name="finalBalance">
          <FinalBalanceCostTrakingPane
            @comment="handleComment"
            @onRefresh="handleRefreshPage"
            :generalInfoId="generalInfoId"
            :costCenterName="headerInfo.projectName"
            :costCenterCode="headerInfo.centerCostCode"
            title="Saldo final"
            v-if="activeTab === 'finalBalance'"
            :info="commentsData.finalBalance"
          />
        </el-tab-pane>
        <el-tab-pane label="Saldo inicial" name="openingBalance">
          <InitialBalanceCostTrakingPane
            @comment="handleComment"
            title="Saldo inicial"
            :info="commentsData.openingBalance"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <DrawerActions
      :isVisible="isDrawerVisible"
      :info="commentInfo"
      @close="handleCloseDrawer"
      @save="handleSaveComment"
    />
  </el-main>
</template>

<script>
import ReturnHeader from '@/components/ReturnHeader/ReturnHeader.vue'
import { getProjectDetails } from '@/service/monthlyProjectDetails'
import GenericCostTrakingPane from './Partials/GenericCostTrakingPane/GenericCostTrakingPane.vue'
import CorrelateCostTrakingPane from './Partials/CorrelateCostTrakingPane/CorrelateCostTrakingPane.vue'
import InitialBalanceCostTrakingPane from './Partials/InitialBalanceCostTrakingPane/InitialBalanceCostTrakingPane.vue'
import FinalBalanceCostTrakingPane from './Partials/FinalBalanceCostTrakingPane/FinalBalanceCostTrakingPane.vue'
import DrawerActions from './Partials/DrawerActions/DrawerActions.vue'
import {
  fetchGetCommentsCostTracking,
  fetchUpsertCommentsCostTracking,
} from '@/service/commentCostTracking'

export default {
  name: 'CostTrackingPage',
  data() {
    return {
      frId: '',
      headerInfo: {},
      activeTab: '',
      isDrawerVisible: false,
      commentsData: {
        equip: {},
        civil: {},
        hr: {},
        books: {},
        consumables: {},
        trips: {},
        training: {},
        external: {},
        correlates: {},
        expenses: {},
        costs: {},
        total: {},
        contribution: {},
        finalBalance: {},
        openingBalance: {},
      },
      generalInfoId: '',
      commentInfo: {},
    }
  },
  components: {
    ReturnHeader,
    GenericCostTrakingPane,
    CorrelateCostTrakingPane,
    InitialBalanceCostTrakingPane,
    FinalBalanceCostTrakingPane,
    DrawerActions,
  },
  methods: {
    handleRefreshPage() {
      this.handleGetComments(Object.keys(this.commentsData))
    },
    handleTabClick({ name }) {
      this.activeTab = name
      this.$router.push({
        name: 'CostTrackingPage',
        params: {
          id: this.generalInfoId,
          frId: this.frId,
          tabName: name,
        },
      })
    },
    handleCloseDrawer() {
      this.isDrawerVisible = false
    },
    handleComment({ rowInfo }) {
      this.commentInfo = rowInfo
      this.isDrawerVisible = true
    },
    async handleSaveComment(sendData) {
      const loading = this.$loading({
        lock: true,
        text: 'Salvando comentários...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const { data } = await fetchUpsertCommentsCostTracking({
          ...sendData,
          financialReportId: this.frId,
        })
        if (data) {
          this.commentsData[sendData.rubric] = { ...Object.values(data)[0] }
          this.isDrawerVisible = false
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Comentário realizado com sucesso!',
            duration: 5000,
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    async handleProjectDetails() {
      try {
        const { data } = await getProjectDetails(this.frId)
        if (data) {
          const { centerCostCode, projectName, startDay, endDay } = data
          this.headerInfo = {
            centerCostCode,
            projectName,
            startDay,
            endDay,
          }
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async handleGetComments(tags) {
      try {
        const { data } = await fetchGetCommentsCostTracking(
          tags.toString(),
          this.generalInfoId
        )
        if (data) {
          tags.forEach((tag) => {
            this.commentsData[tag] = data[tag]
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data,
          duration: 5000,
          sshowClose: true,
        })
      }
    },
  },
  computed: {
    getActiveTab() {
      return this.activeTab
    },
  },
  async created() {
    this.generalInfoId = this.$route.params.id
    this.frId = this.$route.params.frId
    await this.handleGetComments(Object.keys(this.commentsData))

    this.activeTab = this.$route.params.tabName
    await this.handleProjectDetails()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
